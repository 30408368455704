import { ThemeProvider } from '@mui/material'
import { TcNavbar } from '@tc-packages/tc-common'
import PropTypes from 'prop-types'
import React from 'react'
import TCTheme from './TCTheme'

const TCNavbarWrapper = ({
  activeLocaleCode,
  apiSearch,
  cartConfig,
  cartPath,
  data,
  isInvestor,
  localeFlags,
  locales,
  logoUrl = { uri: window.location.origin },
  profileData,
  isShop,
  showStickySubHeader,
  t,
}) => (
  <ThemeProvider theme={TCTheme}>
    <TcNavbar
      activeLocaleCode={activeLocaleCode}
      apiSearch={apiSearch}
      cartConfig={cartConfig}
      cartPath={cartPath}
      isInvestor={isInvestor}
      isShop={isShop}
      localeFlags={localeFlags}
      locales={locales}
      logo={data.logo}
      logoUrl={logoUrl.uri}
      menuItems={data.menuItems}
      profileData={profileData}
      showStickySubHeader={showStickySubHeader}
      t={t}
    />
  </ThemeProvider>
)

TCNavbarWrapper.propTypes = {
  activeLocaleCode: PropTypes.string,
  apiSearch: PropTypes.object,
  cartConfig: PropTypes.object,
  cartPath: PropTypes.string,
  data: PropTypes.object,
  isInvestor: PropTypes.bool,
  isShop: PropTypes.bool,
  localeFlags: PropTypes.string,
  locales: PropTypes.object,
  logoUrl: PropTypes.object,
  pathOrigin: PropTypes.string,
  profileData: PropTypes.object,
  showStickySubHeader: PropTypes.bool,
  t: PropTypes.any,
}

export default TCNavbarWrapper
