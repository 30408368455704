import React, { useState } from 'react'
import PropTypes from 'prop-types'
import useApiRequest from '../hooks/useApiRequest'
import { MainModal } from '@tc-packages/tc-common'

const RemoveBrigadeButton = ({ path, t }) => {
  const { sendRequest } = useApiRequest()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleRemoveClick = (e) => {
    e.preventDefault()
    setIsModalOpen(true)
  }

  const handleConfirmRemove = async () => {
    setIsLoading(true)
    try {
      await sendRequest(path, 'DELETE')
      window.location.reload()
    } catch (error) {
      window.alert(t['tcnext.failed_to_remove_brigade'])
    } finally {
      setIsLoading(false)
      setIsModalOpen(false)
    }
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const modalBody = (
    <div className="py-5">
      <div className="remove-brigade-modal__header">
        <h2 className="remove-brigade-modal__title" id="modal-title">
          {t['tcnext.remove_brigade.wait']}
        </h2>
        <h3 className="remove-brigade-modal__subtitle">
          {t['tcnext.remove_brigade.confirm_leave_program']}
        </h3>
      </div>
      <div className="remove-brigade-modal__body" id="modal-description">
        <p className="remove-brigade-modal__text">
          {t['tcnext.remove_brigade.leave_program_description']}
        </p>
      </div>
      <div className="remove-brigade-modal__actions">
        <button
          className="buttonComponent--primary buttonComponent--medium"
          disabled={isLoading}
          onClick={handleCancel}
        >
          {t['tcnext.remove_brigade.no_go_back']}
        </button>
        <button
          className="buttonComponent--secondary buttonComponent--medium"
          disabled={isLoading}
          onClick={handleConfirmRemove}
        >
          {isLoading
            ? t['tcnext.remove_brigade.loading']
            : t['tcnext.remove_brigade.confirm_leave']}
        </button>
      </div>
    </div>
  )

  return (
    <>
      <a className="deleteBrigade" onClick={handleRemoveClick}>
        <i className="icon-times"></i>
      </a>
      <MainModal
        additionalClasses="remove-brigade-modal small-modal"
        handleClose={handleCancel}
        isModalOpen={isModalOpen}
        modalAttributes={{
          'aria-describedby': 'modal-description',
          'aria-labelledby': 'modal-title',
          'aria-modal': 'true',
          role: 'dialog',
        }}
        modalBody={modalBody}
      />
    </>
  )
}

RemoveBrigadeButton.propTypes = {
  path: PropTypes.string.isRequired,
  t: PropTypes.object.isRequired,
}

export default RemoveBrigadeButton
