import PropTypes from 'prop-types'
import React from 'react'
import { Modal } from '@mui/material'
import { SanitizeHTML } from '@tc-packages/tc-common'

export default class GlobalLead extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      openModal: false,
    }
  }

  handleOpenModal = (e) => {
    e.preventDefault()
    this.setState({ openModal: true })
  }

  handleCloseModal = () =>
    this.setState({
      openModal: false,
    })

  render() {
    const { leadImg, leadImgAlt, leadName, leadTitle, leadBio } = this.props
    return (
      <React.Fragment>
        <>
          <a
            className="global-lead-modal"
            href="#"
            onClick={(e) => this.handleOpenModal(e)}
          />
          <Modal
            className="modal-material"
            onClose={() => this.handleCloseModal()}
            open={this.state.openModal}
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body">
                  <button
                    className="closeModalButton"
                    onClick={() => this.handleCloseModal()}
                  >
                    <span>+</span>
                  </button>
                  <div className="global-lead-modal__content">
                    <img
                      alt={leadImgAlt}
                      className={'global-lead-modal__img'}
                      src={leadImg}
                      width="100%"
                    />
                    <h4 className="global-lead-modal__name">{leadName}</h4>
                    <div className="global-lead-modal__title">{leadTitle}</div>
                    <p className="global-lead-modal__txt">
                      {<SanitizeHTML html={leadBio} />}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
          <div className="cardComponent_additionalText">
            <div className="cardComponent_topContent">
              <h4 className="cardComponent_title">
                <strong>{leadName}</strong>
              </h4>
              <h5 className="cardComponent_title">
                <strong>{leadTitle}</strong>
              </h5>
              <div className="cardComponent_desc">
                {<SanitizeHTML html={leadBio} />}
              </div>
              <a
                className="global-lead-modal"
                href="#"
                onClick={(e) => this.handleOpenModal(e)}
              >
                See more
              </a>
            </div>
          </div>
        </>
      </React.Fragment>
    )
  }
}

GlobalLead.propTypes = {
  leadBio: PropTypes.string,
  leadImg: PropTypes.string,
  leadImgAlt: PropTypes.string,
  leadName: PropTypes.string,
  leadTitle: PropTypes.string,
}
